import { call, put, select } from 'redux-saga/effects'
import {
  SYNCHRO_FUSION_SUCCESS,
  SYNCHRO_FUSION_FAILURE
} from '../actions/importer'
import { Config } from '../config'
import { fetchWithCredentials } from '../util/fetch'
import { selectIdToken } from '../selectors/session'
import { isNullish } from '../util/data'
import { handleUnauthorizedResponseError } from './helper'
import { HPRIM } from '../types/shared/v1/HPRIM'

export function* synchroFusionSaga(action: any): any {
  const idToken = yield select(selectIdToken)

  let patientData
  let pipelinesResult
  try {
    patientData = getJsonPatientData(action.patientData)
    pipelinesResult = yield call(findPipeline, idToken)
    yield call(executePipeline, idToken, patientData, pipelinesResult)
    // On signale que le pipeline s'exécute
    yield call(pollPipelineExecution, idToken)
    // On signale que le pipeline s'est bien exécuté
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    console.log(err)
    yield put({ type: SYNCHRO_FUSION_FAILURE, err })
    return
  }
  // On signale que la synchro est ok
  yield put({ type: SYNCHRO_FUSION_SUCCESS })
}

let pipelineID: string
let runID: string

function getJsonPatientData(patientData: any): any {
  const input: any = {
    Input: {
      inlogID: patientData.inlogID,
      lastname: patientData.lastname,
      firstname: patientData.firstname,
      birthDate: patientData.birthDate,
      gender: patientData.gender,
      comments: patientData.comments,
      results: patientData.untestedSamples,
      dates: patientData.dates
    }
  }

  return input
}

function fillPatientDataFromResult(result: any): any {
  let input: any = {
    Input: {
      inlogID: '',
      lastname: '',
      firstname: '',
      birthDate: '',
      gender: '',
      comments: {},
      links: {},
      results: {},
      untestedSamples: {},
      dates: {}
    }
  }
  input = fillPatientInfo(result, input)
  input = fillComments(result, input)
  input = fillLinks(result, input)
  input = fillResults(result, input)

  return input
}

async function getInlogDataSync(file: any): Promise<any> {
  return await new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = (e: any) => {
      resolve(e.currentTarget.result)
    }
    fileReader.readAsBinaryString(file)
  }).then((result: any) => result)
}

async function convertFile(idToken: string, result: string): Promise<any> {
  return await fetchWithCredentials(
    idToken,
    `${Config.converterServerURL}/api/v1/converters/hprim`,
    {
      headers: {
        'Content-Type': 'text/plain'
      },
      method: 'POST',
      body: result
    }
  )
    .then(async (response) => await response.json())
    .then((converted) => {
      return converted
    })
};

async function findPipeline(idToken: string): Promise<any> {
  return await fetchWithCredentials(
    idToken,
    `${Config.pipelineServerURL}/api/v1/pipelines?name=sheila`,
    {
      method: 'GET'
    }
  )
    .then(async (response) => await response.json())
    .then((pipelinesResult) => pipelinesResult)
}

async function executePipeline(idToken: string, patientData: any, pipelinesResult: any): Promise<any> {
  return await fetchWithCredentials(
    idToken,
    `${Config.pipelineServerURL}/api/v1/pipelines/${String(pipelinesResult.Data[0].ID)}/runs`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(patientData)
    }
  )
    .then(async (response) => await response.json())
    .then((pipelineExecutionResult) => {
      pipelineID = pipelineExecutionResult.Data.Run.PipelineID
      runID = pipelineExecutionResult.Data.Run.ID

      return pipelineExecutionResult
    })
}

function fillPatientInfo(result: any, input: any): any {
  for (let i = 0; i < result[0].Segments.length; i++) {
    if (result[0].Segments[i].Type === 'P') {
      input.Input.inlogID = result[0].Segments[i].Fields[1].RawValue
      input.Input.lastname = result[0].Segments[i].Fields[4].Subs[0].RawValue
      input.Input.firstname = result[0].Segments[i].Fields[4].Subs[1].RawValue
      input.Input.birthDate = result[0].Segments[i].Fields[6].RawValue
      input.Input.gender = result[0].Segments[i].Fields[7].RawValue
      break
    }
  }
  return input
}

function fillComments(result: any, input: any): any {
  let currentCommentIndex: number = 0
  let currentAddendumIndex: number = 0
  let currentCommentContent: any = {}
  let commentType: string = ''
  for (let i = 0; i < result[0].Segments.length; i++) {
    if (result[0].Segments[i].Type === 'C') {
      // On récupère le type du commentaire et on crée l'entrée générale du tableau si elle n'existe pas pour ce type
      commentType = result[0].Segments[i].Fields[2].Subs[0].RawValue

      if (commentType === 'LIEN') {
        continue
      }

      if (isNullish(input.Input.comments[commentType])) {
        input.Input.comments[commentType] = []
      }

      // On stocke le commentaire sous la forme d'un tableau pour l'exploiter facilement dans l'interface
      // On ne stocke pas le type car on est deja dans un tableau dont la clé est le type
      const comment: string[] = []
      for (let j = 1; j < result[0].Segments[i].Fields[2].Subs.length; j++) {
        comment.push(result[0].Segments[i].Fields[2].Subs[j].RawValue)
      }
      currentCommentContent = comment

      // On contrôle l'intégrité des commentaires selon leur type
      let checkIndex = 0
      switch (commentType) {
        case 'COMMENTAIRE':
          checkIndex = 3
          break
        case 'EVENEMENT':
          checkIndex = 2
          break
        case 'PATHOLOGIE':
          checkIndex = 1
          break
        default:
          checkIndex = 0
          break
      }

      if (!isNullish(currentCommentContent[checkIndex]) && currentCommentContent[checkIndex].length > 1) {
        input.Input.comments[commentType].push(currentCommentContent)
      }
      currentCommentIndex = i
    }

    // On limite les addendums aux commentaires pour le moment
    if (result[0].Segments[i].Type === 'A' && commentType === 'COMMENTAIRE') {
      // Si le commentaire fait plusieurs lignes, on ajoute les ou les addendums à la suite de la dernière entrée
      if (i === currentCommentIndex + 1 || i === currentAddendumIndex + 1) {
        currentCommentContent[3] = String(currentCommentContent[3]) + String(result[0].Segments[i].Fields[0].RawValue)
        input.Input.comments[commentType][input.Input.comments.length - 1] = currentCommentContent
      }
      currentAddendumIndex = i
    }
  }
  return input
}

function fillLinks(result: any, input: any): any {
  let commentType: string = ''
  const links: any = {}
  const types: any = {}
  let currentPatientIndex = 0
  let currentPatientId

  for (let i = 0; i < result[0].Segments.length; i++) {
    if (result[0].Segments[i].Type === 'C') {
      // On récupère le type du commentaire et on crée l'entrée générale du tableau si elle n'existe pas pour ce type
      commentType = result[0].Segments[i].Fields[2].Subs[0].RawValue

      if (commentType === 'LIEN') {
        const linkType = result[0].Segments[i].Fields[2].Subs[1].RawValue
        const linkId = result[0].Segments[i].Fields[2].Subs[2].RawValue

        if (isNullish(links[linkType])) {
          links[linkType] = {}
        }

        if (isNullish(links[linkType][linkId])) {
          links[linkType][linkId] = {}
          types[linkId] = linkType
        }
      }
    }

    if (result[0].Segments[i].Type === 'P' && result[0].Segments[i].Fields[0].RawValue !== '1') {
      const patientId = result[0].Segments[i].Fields[1].RawValue
      links[types[patientId]][patientId] = {
        lastname: result[0].Segments[i].Fields[4].Subs[0].RawValue,
        firstname: result[0].Segments[i].Fields[4].Subs[1].RawValue,
        birthDate: result[0].Segments[i].Fields[6].RawValue,
        gender: result[0].Segments[i].Fields[7].RawValue,
        typings: {}
      }
      currentPatientIndex = i
      currentPatientId = patientId
    }

    if (result[0].Segments[i].Type === 'C' && currentPatientIndex !== 0 && (i === currentPatientIndex + 1 || i === currentPatientIndex + 2)) {
      const comment: string[] = []
      for (let j = 1; j < result[0].Segments[i].Fields[2].Subs.length; j++) {
        const typages = result[0].Segments[i].Fields[2].Subs[j].RawValue.split('-')
        comment.push(typages[1].replace(/~/g, ''))
      }
      links[types[currentPatientId]][currentPatientId].typings[result[0].Segments[i].Fields[2].Subs[0].RawValue] = comment
    }
  }

  input.Input.links = links

  return input
}

function fillResults(result: HPRIM[], input: any): any {
  let currentOBRContent: any = {}
  let hasOBX: boolean = false
  let currentOBRType: string = ''

  for (let i = 0; i < result[0].Segments.length; i++) {
    // Si on tombe sur un nouvel OBR ou qu'on a terminé la liste des OBR, on traite l'OBR en cours ainsi que ses potentiels OBX
    if (result[0].Segments[i].Type === 'OBR' || (result[0].Segments[i].Type !== 'OBR' && result[0].Segments[i].Type !== 'OBX')) {
      if (!isNullish(currentOBRContent)) {
        if (hasOBX) {
          // Si on a trouvé un OBX, on valide le prélèvement et son résultat
          input.Input.results[currentOBRContent.result] = currentOBRContent
          hasOBX = false
        } else {
          // Si le champ précédent était un OBR sans OBX on le place dans les prélèvements non testés, c'est un prélèvement en attente
          currentOBRContent.type = currentOBRType
          if (isNullish(input.Input.untestedSamples[currentOBRContent.result])) {
            input.Input.untestedSamples[currentOBRContent.result] = {
              demands: {}
            }
          }
          if (isNullish(input.Input.untestedSamples[currentOBRContent.result].demands[currentOBRContent.subSampleID])) {
            input.Input.untestedSamples[currentOBRContent.result].demands[currentOBRContent.subSampleID] = {
              date: currentOBRContent.date,
              types: [],
              metadatas: {
                priority: ''
              }
            }
          }
          input.Input.untestedSamples[currentOBRContent.result].demands[currentOBRContent.subSampleID].types.push(currentOBRContent.type)
          input.Input.untestedSamples[currentOBRContent.result].demands[currentOBRContent.subSampleID].metadatas = {
            ...(input.Input.untestedSamples[currentOBRContent.result].demands[currentOBRContent.subSampleID].metadatas ?? {}),
            // Extract sample priority. See https://forge.cadoles.com/EFS/sheila/issues/250
            priority: currentOBRContent.priority
          }
        }
        currentOBRContent = {}
      }
    }

    if (result[0].Segments[i].Type === 'OBR') {
      currentOBRType = result[0].Segments[i].Fields[3].RawValue

      if (!isNullish(result[0].Segments[i].Fields[1].Subs)) {
        currentOBRContent = {
          result: (result[0].Segments[i].Fields[1].Subs ?? [])[0].RawValue,
          subSampleID: (result[0].Segments[i].Fields[1].Subs ?? [])[1].RawValue,
          index: (result[0].Segments[i].Fields[1].Subs ?? [])[0].RawValue,
          date: result[0].Segments[i].Fields[5].RawValue.padEnd(14, '0')
        }
      } else {
        currentOBRContent = {
          result: result[0].Segments[i].Fields[1].RawValue,
          subSampleID: result[0].Segments[i].Fields[1].RawValue,
          index: result[0].Segments[i].Fields[1].RawValue,
          date: result[0].Segments[i].Fields[5].RawValue.padEnd(14, '0')
        }
      }

      // Extract sample priority. See https://forge.cadoles.com/EFS/sheila/issues/250
      currentOBRContent.priority = result[0].Segments[i].Fields[4].RawValue
    }

    // Si le champ précédent était un OBR et que l'OBX est de type HD1 ou HD2 on le valide, c'est un prélèvement valide
    if (result[0].Segments[i].Type === 'OBX') {
      hasOBX = true
      if (String(result[0].Segments[i].Fields[2].RawValue).startsWith('HD1') || String(result[0].Segments[i].Fields[2].RawValue).startsWith('HD2')) {
        currentOBRContent.type = result[0].Segments[i].Fields[2].RawValue.substr(0, 3)
      }
    }
  }

  return input
}

async function pollPipelineExecution(idToken: string): Promise<any> {
  return await poll(
    pollPipeline.bind(null, idToken),
    1000,
    20
  )
    .then((pollResult) => pollResult)
}

const pollPipeline = async (idToken: string): Promise<any> => {
  return await fetchWithCredentials(
    idToken,
    `${Config.pipelineServerURL}/api/v1/pipelines/${pipelineID}/runs/${runID}`,
    {
      method: 'GET'
    }
  )
}

const poll = async (fn: any, interval: number, maxAttempts: number): Promise<void> => {
  let attempts = 0

  const checkCondition = function (resolve: (value: void | PromiseLike<void>) => void, reject: (reason?: any) => void): void {
    attempts++
    const ajax = fn()
    ajax.then((response: any) => response.json())
      .then((pollingResult: any) => {
        if (pollingResult.Data.Run.State.toString() === '3') {
          resolve()
        } else if (maxAttempts > 0 && attempts < maxAttempts) {
          setTimeout(checkCondition, interval, resolve, reject)
        } else {
          reject(new Error('timeout'))
        }
      })
  }

  return await new Promise(checkCondition)
}
