export class ExtendableError extends Error {
  constructor (message) {
    super(message)
    Object.setPrototypeOf(this, ExtendableError.prototype)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

export class InvalidRequestError extends ExtendableError {
  response: any

  constructor (response: any) {
    super('Invalid request')
    Object.setPrototypeOf(this, InvalidRequestError.prototype)
    this.response = response
  }

  getResponse (): any {
    return this.response
  }
}

export class ServerError extends ExtendableError {
  response: any

  constructor (response: any) {
    super('Une erreur est survenue')
    Object.setPrototypeOf(this, ServerError.prototype)
    this.response = response
  }

  getResponse (): any {
    return this.response
  }
}
