import { fetchWithCredentials } from './fetch'
import { Pipeline, PipelineRun, PipelineRunState } from '../types/Pipeline';
import { poll } from './poll';

export class PipelineServerClient {
    baseUrl: string
    idToken: string

    constructor(baseUrl: string, idToken: string) {
        this.baseUrl = baseUrl
        this.idToken = idToken

        // Force bind methods to instance
        this.fetchPipelineByName = this.fetchPipelineByName.bind(this)
        this.runPipeline = this.runPipeline.bind(this)
        this.fetchRun = this.fetchRun.bind(this)
        this.waitForRun = this.waitForRun.bind(this)
    }

    fetchPipelineByName(name: string): Promise<Pipeline> {

        return fetchWithCredentials(
            this.idToken,
            `${this.baseUrl}/api/v1/pipelines?name=${encodeURIComponent(name)}`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            }
        )
            .then(res => res.json())
            .then(res => {
                if ((res.Data || []).length === 0) {
                    throw new Error("Pipeline not found")
                }

                return res.Data[0] as Pipeline
            })
    }

    runPipeline(pipelineId: string, input: any): Promise<PipelineRun> {
        return fetchWithCredentials(
            this.idToken,
            `${this.baseUrl}/api/v1/pipelines/${pipelineId}/runs`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({ input })
            }
        )
            .then(res => res.json())
            .then((res) => {
                return res.Data.Run as PipelineRun
            })
    }

    fetchRun(pipelineId: string, pipelineRunId: string): Promise<PipelineRun> {
        return fetchWithCredentials(
            this.idToken,
            `${this.baseUrl}/api/v1/pipelines/${pipelineId}/runs/${pipelineRunId}`,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            }
        )
            .then(res => res.json())
            .then(res => {
                return res.Data.Run as PipelineRun
            })
    }

    waitForRun(pipelineId: string, pipelineRunId: string) {
        return poll<PipelineRun>(
            () => this.fetchRun(pipelineId, pipelineRunId),
            (pipelineRun: PipelineRun) => pipelineRun.State !== PipelineRunState.Running && pipelineRun.State !== PipelineRunState.Created,
            1000,
            600
        )
    }

}