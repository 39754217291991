import { Action } from 'redux'
import { put, race, take } from 'redux-saga/effects'
import { refreshAccessToken, REFRESH_ACCESS_TOKEN_FAILURE, REFRESH_ACCESS_TOKEN_SUCCESS } from '../actions/session'
import { UnauthorizedResponseError } from '../util/fetch'

export function * handleUnauthorizedResponseError (error: Error, action: Action): Generator<any, any, any> {
  if (!(error instanceof UnauthorizedResponseError)) return
  // Try to refresh access token
  yield put(refreshAccessToken())

  // We wait for either success or failure
  const { success }: any = yield race({
    success: take(REFRESH_ACCESS_TOKEN_SUCCESS),
    failure: take(REFRESH_ACCESS_TOKEN_FAILURE)
  })

  // If refresh succeeded
  if (success !== undefined) {
    // Replay action
    yield put(action)
  }

  return true
}
