import { asDate } from './date'
import { InvalidRequestError, ServerError } from './error'
import { fetchWithCredentials } from './fetch'
import { ObjectFilter, QueryObjectsOptions } from './objects'

interface EventsResult {
  Data: {
    Events: EventObject[]
  }
}

export enum EventObjectType {
  OBJECT_CREATED = 'object-created',
  OBJECT_UPDATED = 'object-updated'
}

export interface EventHeader {
  id: string
  createdAt: Date
  tenant: string
  type: string
}

export interface EventObject<T = any> extends EventHeader {
  data: T
}

export class EventServerClient {
  baseUrl: string
  idToken: string

  constructor(baseUrl: string, idToken: string) {
    this.baseUrl = baseUrl
    this.idToken = idToken

    this.fetchEvents = this.fetchEvents.bind(this)
  }

  async fetchEvents(options?: QueryObjectsOptions, filter?: ObjectFilter): Promise<EventsResult[]> {
    //     Pour ce qui est du requêtage, tu as les paramètres d'URL suivants:
    // filter pour filtrer via le contenu de la propriété Data des différents évènements;
    // types pour filtrer par type d'évènement (en mode csv)
    // before/after pour filtrer par date de création de l'évènement
    // limit/offset pour la pagination
    let queryString: string[] = []
    if (options?.offset !== undefined) {
      queryString.push(`offset=${options.offset}`)
    }
    if (options?.limit !== undefined) {
      queryString.push(`limit=${options.limit}`)
    }

    if (filter !== undefined) {
      queryString.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`)
    }

    if (options?.before !== undefined) {
      queryString.push(`before=${options.before}`)
    }

    if (options?.after !== undefined) {
      queryString.push(`after=${options.after}`)
    }

    const url = `${this.baseUrl}/api/v1/_events`
    const res = await fetchWithCredentials(this.idToken, url, {
      method: 'GET'
    })

    if (res.status === 400) {
      throw new InvalidRequestError(res)
    }

    if (res.status >= 500) {
      throw new ServerError(res)
    }

    const result = await res.json()
    return result.Data.Events.map((event => itemToEventObject(event)))
  }
}

function itemToObjectHeader(item: any): EventHeader {
  return {
    id: item.ID,
    createdAt: asDate(item.CreatedAt),
    type: item.Type,
    tenant: item.Tenant
  }
}

function itemToEventObject(item: any): EventObject | EventHeader {
  const obj: EventObject | EventHeader = {
    ...itemToObjectHeader(item),
  }

  if (item.Data) {
    (obj as EventObject).data = item.Data
  }

  return obj
}
