import { createSelector } from "reselect";
import {
  ANALYSIS_REQUEST_NAMESPACE,
  ANALYSIS_REAGENT_PROVIDER_NAMESPACE,
  ANALYSIS_REQUEST_CATEGORY_NAMESPACE,
  ANALYSIS_RESULT_GROUP_NAMESPACE,
  ANALYSIS_REQUEST_CODE_NAMESPACE,
} from "../actions/analysis-request";
import { RootState } from "../reducers/root";
import {
  AnalysisReagentProvider,
  AnalysisRequest,
  AnalysisRequestCategories,
  AnalysisRequestCategory,
  AnalysisRequestCode,
  AnalysisRequestState,
  AnalysisRequestType,
} from "../types/shared/v2/AnalysisRequest";
import { ObjectHeader, PrivacyDomain, StoreObject } from "../util/objects";
import {
  AnalysisResultGroup,
  AnalysisResultGroupType,
} from "../types/shared/v2/AnalysisResultGroup";
import { selectObjectByAttr } from "./objects";
import { Store } from "redux";

export const selectAnalysisRequestObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisRequest> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REQUEST_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;
};
export const selectAnalysisRequestObjectsArray = (
  state: RootState
): (StoreObject<AnalysisRequest> | ObjectHeader)[] => {
  return Object.values(
    state.objects.byNamespace[ANALYSIS_REQUEST_NAMESPACE]?.byDomain[
      PrivacyDomain.Public
    ]?.byId || {}
  );
};

export const selectPendingAnalysisRequests = createSelector(
  [selectAnalysisRequestObjects],
  (objects) => {
    const analysisRequest: AnalysisRequest[] = [];
    if (!objects) return analysisRequest;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;

      const analysisTypes = Object.keys(
        obj.data.types
      ) as AnalysisRequestType[];

      const isPending =
        analysisTypes.some(
          (typ) => obj.data.types[typ] === AnalysisRequestState.TakenIntoAccount
        ) &&
        analysisTypes.some((typ) =>
          Object.values(AnalysisRequestCategories).some((cat) =>
            cat.types.has(typ)
          )
        );

      if (!isPending) return memo;

      memo.push(obj.data);

      return memo;
    }, analysisRequest);
  }
);

export const selectPatientPendingAnalysisRequests = createSelector(
  [
    selectPendingAnalysisRequests,
    (state: RootState, inlogId: string) => inlogId,
  ],
  (pendingAnalyses, inlogId) => {
    const analysisRequest: AnalysisRequest[] = [];
    if (!pendingAnalyses) return analysisRequest;

    return Object.values(pendingAnalyses).reduce((memo, pending) => {
      if (pending.ref.patient.inlogId !== inlogId) return memo;
      memo.push(pending);
      return memo;
    }, analysisRequest);
  }
);

export const selectAnalysisReagentProviderIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisReagentProvider> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REAGENT_PROVIDER_NAMESPACE]
    ?.byDomain[PrivacyDomain.Public]?.byId;
};

export const selectAnalysisReagentProviderObjects = createSelector(
  [selectAnalysisReagentProviderIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisReagentProvider>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);

export const selectAnalysisReagentProviders = createSelector(
  [selectAnalysisReagentProviderObjects],
  (objects) => {
    return objects.map((obj) => obj.data);
  }
);

export const selectAnalysisReagentProviderByCode = (
  state: RootState,
  code: string
) => {
  return selectObjectByAttr<AnalysisReagentProvider>(
    state,
    ANALYSIS_REAGENT_PROVIDER_NAMESPACE,
    PrivacyDomain.Public,
    "code",
    code
  );
};

export const selectAnalysisRequestCategoryIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisRequestCategory> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REQUEST_CATEGORY_NAMESPACE]
    ?.byDomain[PrivacyDomain.Public]?.byId;
};

export const selectAnalysisRequestCategoryObjects = createSelector(
  [selectAnalysisRequestCategoryIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisRequestCategory>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);

export const selectAnalysisRequestCategories = createSelector(
  [selectAnalysisRequestCategoryObjects],
  (objects) => {
    return objects.map((obj) => obj.data);
  }
);

export const selectAnalysisRequestCategoryByCode = (
  state: RootState,
  code: string
) => {
  return selectObjectByAttr<AnalysisRequestCategory>(
    state,
    ANALYSIS_REQUEST_CATEGORY_NAMESPACE,
    PrivacyDomain.Public,
    "code",
    code
  );
};

export const selectAnalysisResultGroupIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisResultGroup> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_RESULT_GROUP_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;
};

export const selectAnalysisResultGroupObjects = createSelector(
  [selectAnalysisResultGroupIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisResultGroup>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);

export const selectDefaultAnalysisResultGroupObjects = createSelector(
  [
    selectAnalysisResultGroupObjects,
  ],
  (objects) => {
    const resultGroups: StoreObject<AnalysisResultGroup>[] = objects.reduce(
      (resultGroups, obj) => {
        if (obj.data.ref.patient === undefined) {
          resultGroups.push(obj);
        }

        return resultGroups;
      },
      [] as StoreObject<AnalysisResultGroup>[]
    );

    return resultGroups;
  }
);

export const selectAnalysisResultGroupObjectsByInlogIdOrDefaults = createSelector(
  [
    selectDefaultAnalysisResultGroupObjects,
    selectAnalysisResultGroupObjects,
    (state: RootState, inlogId: string) => inlogId,
  ],
  (defaultGroups, allGroups, inlogId) => {

    // On créait un index des groupes à partir des groupes par défaut existants
    const groupIndex: { [code: string]: StoreObject<AnalysisResultGroup> } = {};
    defaultGroups.forEach(group => groupIndex[group.data.code] = group);

    // On surcharge/complète l'index avec chaque groupe existant pour l'inlogId donné
    allGroups.forEach(group => {
      if (group.data.ref.patient?.inlogId !== inlogId) {
        return
      }
      groupIndex[group.data.code] = group;
    })

    return Object.values(groupIndex)
  }
);

export const selectAnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    selectAnalysisResultGroupObjectsByInlogIdOrDefaults,
  ],
  (objects) => objects.map(obj => obj.data)
);

export const selectPunctualAnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.type === AnalysisResultGroupType.Punctual
    );
  }
);

export const selectBinaryAnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.type === AnalysisResultGroupType.Binary
    );
  }
);

export const selectDSA1AnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.type === AnalysisResultGroupType.DSA_CLASS1
    );
  }
);

export const selectDSA2AnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.type === AnalysisResultGroupType.DSA_CLASS2
    );
  }
);

export const selectHistorizedAnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.type === AnalysisResultGroupType.Historized
    );
  }
);

export const selectCrossmatchAnalysisResultGroupByInlogIdOrDefaults = createSelector(
  [
    (state: RootState, inlogId: string) =>
      selectAnalysisResultGroupByInlogIdOrDefaults(state, inlogId),
  ],
  (resultGroups) => {
    return resultGroups.filter(
      (group) => group.type === AnalysisResultGroupType.Historized
    );
  }
);

export const selectAnalysisResultGroups = createSelector(
  [selectAnalysisResultGroupObjects],
  (objects) => {
    return objects.map((obj) => obj.data);
  }
);

export const selectAnalysisRequestCodeIndexedObjects = (
  state: RootState
):
  | { [objectId: string]: StoreObject<AnalysisRequestCode> | ObjectHeader }
  | undefined => {
  return state.objects.byNamespace[ANALYSIS_REQUEST_CODE_NAMESPACE]?.byDomain[
    PrivacyDomain.Public
  ]?.byId;
};

export const selectAnalysisRequestCodeObjects = createSelector(
  [selectAnalysisRequestCodeIndexedObjects],
  (objects) => {
    const items: StoreObject<AnalysisRequestCode>[] = [];
    if (!objects) return items;

    return Object.values(objects).reduce((memo, obj) => {
      if (!("data" in obj)) return memo;
      memo.push(obj);
      return memo;
    }, items);
  }
);
