import { call, put, select } from 'redux-saga/effects'
import {
  EXPORT_CRISTAL_SUCCESS,
  EXPORT_CRISTAL_FAILURE,
  EXPORT_LUMINEX_SUCCESS,
  EXPORT_LUMINEX_FAILURE,
  toggleState,
  forceState,
  ExportCristalDataAction
} from '../actions/exporter'
import { saveAs } from 'file-saver'
import { getMaxMFI, getDailyResStrictPositive, getDailyResByDescThresholds, getHistorisedSynthesis, DailyPosStrictSpecAbbrs, Interpretations } from '../util/hla-result'
import moment from 'moment'
import { Config } from '../config'
import { selectIdToken } from '../selectors/session'
import { fetchWithCredentials } from '../util/fetch'
import { handleUnauthorizedResponseError } from './helper'
import { ISOtoInlogDate, asDate } from '../util/date'
import { ClassName } from '../types/shared/v1/ClassName'
import { ConsolidatedPatientV2 } from '../types/shared/v2/Patient'
import { WorkspaceHLAResult, WorkspaceHLAResults } from '../types/Sample'
import { HPRIM, HPRIMSegment } from '../types/shared/v1/HPRIM'
import { StoreObject } from '../util/objects'
import { AnalysisResultGroup } from '../types/shared/v2/AnalysisResultGroup'


export function* exportCristalDataSaga(action: ExportCristalDataAction): any {
  const idToken = yield select(selectIdToken)

  let hprim
  let json
  try {
    // On met un loader
    yield put(forceState('exported', false))
    yield put(forceState('aborted', false))
    yield put(toggleState('converting'))
    json = createJsonFromSynthesis(action.patient, action.hlaResults, action.resultGroups, action.interpretations)
    yield call(convertJsonToHprim, idToken, json)
    yield put(toggleState('converting'))
    // TODO fix export
    // yield put(exportedSample(action.patient))
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: EXPORT_CRISTAL_FAILURE, err })
    yield put(forceState('aborted', true))
    return
  }
  // On signale que l'export est ok et on recharge la liste des patients
  yield put({ type: EXPORT_CRISTAL_SUCCESS, hprim })
  yield put(forceState('exported', true))
}

// On conserve la date d'exécution de l'export pour références ultérieures
let date: string

export function* exportLuminexDataSaga(action: any): any {
  let text
  try {
    // On met un loader
    yield put(forceState('exported', false))
    yield put(forceState('aborted', false))
    yield put(toggleState('converting'))
    text = createLuminexTextFromPatientsData(action.patients)
    yield put(toggleState('converting'))
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: EXPORT_LUMINEX_FAILURE, err })
    yield put(forceState('aborted', true))
    return
  }
  // On signale que l'export est ok et on recharge la liste des patients
  yield put({ type: EXPORT_LUMINEX_SUCCESS, text })
  yield put(forceState('exported', true))
}

function createLuminexTextFromPatientsData(patients: any): string {
  let text: string = 'LX100IS Patient List \n[Accession#, Dilution Factor] \n'
  patients.forEach((patient: any) => {
    const untestedSamples = patient.untestedSamples
    if (Object.keys(untestedSamples).length > 0) {
      Object.keys(untestedSamples).forEach((untestedSample: string) => {
        text += '0 ' + '0 ' + String(untestedSample) + ' ' + String(patient.firstname) + ' ' + String(patient.lastname) + '\n'
      })
    }
  })

  date = moment(new Date()).format('YYYYMMDDHHmmss')

  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'LUMINEX_LIST_' + date + '.TXT')

  return text
}

// On crée un index car les OBX sont indexés de manière incrémentale
let obxIndex: number

function createJsonFromSynthesis(patient: StoreObject<ConsolidatedPatientV2>, hlaResults: WorkspaceHLAResults, resultGroups: AnalysisResultGroup[], interpretations: Interpretations): string {
  const segments: HPRIMSegment[] = []

  // TODO revoir la structure pour intégrer classe 1 et 2
  const dailyResult = Object.values(hlaResults.byClass[ClassName.CLASS1])[0]

  obxIndex = 1

  date = moment(new Date()).format('YYYYMMDDHHmmss')

  // On démarre par l'entête
  const formattedSynthesis = getSynthesisHeader(segments)

  // On ajoute les infos du patient
  fillPatientInfo(segments, patient.data)

  // On ajoute les infos du sérum du jour
  // TODO revoir la structure pour intégrer classe 1 et 2
  fillSerumInfo(segments, dailyResult)

  // TODO gérer l'OBX commentaire

  // On ajoute le résultat max du jour
  // TODO revoir la structure pour intégrer classe 1 et 2
  fillDailyMax(segments, dailyResult)

  // On ajoute les résultats du jour directement interdits (dernier et avant dernier niveau de paramétrage du jour)
  // TODO Désactivé temporairement (résultat vide) -
  // Utiliser les codes d'examens et les groupes de résultat dynamiques pour récupérer
  // les données à injecter dans la synthèse Cristal
  const dailyResStrictPositive = getDailyResStrictPositive(dailyResult, resultGroups)
  fillDailyPos(segments, dailyResStrictPositive)

  // On ajoute tous les résultats du jour par ordre décroissant
  // TODO Désactivé temporairement (résultat vide) -
  // Utiliser les codes d'examens et les groupes de résultat dynamiques pour récupérer
  // les données à injecter dans la synthèse Cristal
  const dailyResByDescThresholds = getDailyResByDescThresholds(dailyResult, resultGroups)
  fillOrderedDailyResults(segments, dailyResStrictPositive, dailyResByDescThresholds)

  // On ajoute les billes témoin du jour
  fillDailyTestBeads(segments, dailyResult)

  // On ajoute le lot
  fillLotInfo(segments, dailyResult)

  // On ajoute les interdits, zones grises et permis
  const synthesis = getHistorisedSynthesis(interpretations)
  fillSynthesis(segments, synthesis, dailyResult)

  // On ajoute le texte du protocole
  fillProtocolInfo(segments)

  // On ajoute les deux lignes de clotures
  fillSynthesisFooter(segments)

  return JSON.stringify(formattedSynthesis)
}

function getSynthesisHeader(segments: HPRIMSegment[]): HPRIM[] {
  return [{
    Header: {
      Type: 'H',
      Fields: [
        {
          RawValue: 'FU_' + date.substr(2, 6) + '.HPR',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'LUMINEX^LUMINEX',
          Subs: [
            {
              RawValue: 'LUMINEX',
              Subs: null
            },
            {
              RawValue: 'LUMINEX',
              Subs: null
            }
          ]
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'ORU',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'HLA^INLOG',
          Subs: [
            {
              RawValue: 'HLA',
              Subs: null
            },
            {
              RawValue: 'INLOG',
              Subs: null
            }
          ]
        },
        {
          RawValue: 'HLA Fusion F4.4.0',
          Subs: null
        },
        {
          RawValue: 'T',
          Subs: null
        },
        {
          RawValue: 'H2.1',
          Subs: null
        },
        {
          RawValue: date,
          Subs: null
        }
      ],
      FieldSeparator: 124,
      SubfieldSeparator: 94,
      SubSubfieldSeparator: 38,
      Escape: 92,
      Repeater: 126
    },
    Segments: segments
  }]
}

function fillPatientInfo(segments: HPRIMSegment[], patient: ConsolidatedPatientV2): void {
  segments.push(
    {
      Type: 'P',
      Fields: [
        {
          RawValue: '1',
          Subs: null
        },
        {
          RawValue: patient.inlogId,
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: String(patient.lastname) + '^' + String(patient.firstname),
          Subs: [
            {
              RawValue: patient.lastname,
              Subs: null
            },
            {
              RawValue: patient.firstname,
              Subs: null
            }
          ]
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: patient.birthDate,
          Subs: null
        },
        {
          RawValue: patient.gender,
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO gérer la nationalité
          RawValue: '^^^^^FR',
          Subs: [
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: 'FR',
              Subs: null
            }
          ]
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        }
      ]
    }
  )
}

function fillSerumInfo(segments: HPRIMSegment[], dailyResult: WorkspaceHLAResult): void {
  segments.push(
    {
      Type: 'OBR',
      Fields: [
        {
          RawValue: '1',
          Subs: null
        },
        {
          RawValue: String(dailyResult.sample) + '^' + String(dailyResult.sample),
          Subs: [
            {
              RawValue: dailyResult.sample,
              Subs: null
            },
            {
              RawValue: dailyResult.sample,
              Subs: null
            }
          ]
        },
        {
          // TODO trouver ces valeurs
          RawValue: '53644^49928',
          Subs: [
            {
              RawValue: '53644',
              Subs: null
            },
            {
              RawValue: '49928',
              Subs: null
            }
          ]
        },
        {
          // TODO gérer aussi la classe 2
          RawValue: 'SACL1',
          Subs: null
        },
        {
          // TODO valider cette valeur
          RawValue: 'R',
          Subs: null
        },
        {
          RawValue: ISOtoInlogDate(asDate(dailyResult.batchDate)),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO valider cette valeur
          RawValue: 'A',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO trouver ces valeurs
          RawValue: '^351112^C.H. SAINT MALO^L^0001^Laboratoire de Biologie^L^^^L',
          Subs: [
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '351112',
              Subs: null
            },
            {
              RawValue: 'C.H. SAINT MALO',
              Subs: null
            },
            {
              RawValue: 'L',
              Subs: null
            },
            {
              RawValue: '0001',
              Subs: null
            },
            {
              RawValue: 'Laboratoire de Biologie',
              Subs: null
            },
            {
              RawValue: 'L',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: 'L',
              Subs: null
            }
          ]
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO trouver ces valeurs
          RawValue: 'RENHLA^Laboratoire HLA Rennes',
          Subs: [
            {
              RawValue: 'RENHLA',
              Subs: null
            },
            {
              RawValue: 'Laboratoire HLA Rennes',
              Subs: null
            }
          ]
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        }
      ]
    }
  )
}

function fillDailyMax(segments: HPRIMSegment[], dailyResult: WorkspaceHLAResult): void {
  segments.push(
    {
      Type: 'OBX',
      Fields: [
        {
          RawValue: obxIndex.toString(),
          Subs: null
        },
        {
          RawValue: 'TX',
          Subs: null
        },
        {
          RawValue: 'SA1_COM',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '+',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO Trouver quelle date
          RawValue: ISOtoInlogDate(asDate(dailyResult.batchDate)),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TOD0 Chercher cette veleur
          RawValue: 'ssimonot',
          Subs: null
        }
      ]
    }
  )

  obxIndex++

  segments.push(
    {
      Type: 'C',
      Fields: [
        {
          RawValue: '1',
          Subs: null
        },
        {
          RawValue: 'L',
          Subs: null
        },
        {
          RawValue: '(baseline < ' + String(getMaxMFI(dailyResult)) + ')',
          Subs: null
        }
      ]
    }
  )

  segments.push(
    {
      Type: 'OBX',
      Fields: [
        {
          RawValue: obxIndex.toString(),
          Subs: null
        },
        {
          RawValue: 'NM',
          Subs: null
        },
        {
          RawValue: 'SA1_MAX',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: getMaxMFI(dailyResult).toString(),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO Trouver quelle date
          RawValue: date,
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TOD0 Chercher cette veleur
          RawValue: 'ssimonot',
          Subs: null
        }
      ]
    }
  )

  obxIndex++
}

function fillDailyPos(segments: HPRIMSegment[], dailyResStrictPositive: DailyPosStrictSpecAbbrs): void {
  for (let h = 0; h < dailyResStrictPositive.results.length; h++) {
    segments.push(
      {
        Type: 'OBX',
        Fields: [
          {
            RawValue: obxIndex.toString(),
            Subs: null
          },
          {
            RawValue: 'TX',
            Subs: null
          },
          {
            RawValue: 'SA1_RES',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: dailyResStrictPositive.results[h],
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: 'P',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            // TODO Trouver quelle date
            RawValue: ISOtoInlogDate(asDate(dailyResStrictPositive.date)),
            Subs: null
          },
          {
            RawValue: '',
            Subs: null
          },
          {
            // TOD0 Chercher cette veleur
            RawValue: 'ssimonot',
            Subs: null
          }
        ]
      }
    )

    obxIndex++
  }
}

function fillOrderedDailyResults(segments: HPRIMSegment[], dailyResStrictPositive: DailyPosStrictSpecAbbrs, dailyResByDescThresholds: any): void {
  Object.keys(dailyResByDescThresholds).forEach((key) => {
    for (let i = 0; i < dailyResByDescThresholds[key].length; i++) {
      segments.push(
        {
          Type: 'OBX',
          Fields: [
            {
              RawValue: obxIndex.toString(),
              Subs: null
            },
            {
              RawValue: 'TX',
              Subs: null
            },
            {
              RawValue: key,
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: dailyResByDescThresholds[key][i],
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: 'P',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              // TODO Trouver quelle date
              RawValue: ISOtoInlogDate(asDate(dailyResStrictPositive.date)),
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              // TOD0 Chercher cette veleur
              RawValue: 'ssimonot',
              Subs: null
            }
          ]
        }
      )

      obxIndex++
    }
  })
}

function fillDailyTestBeads(segments: HPRIMSegment[], dailyResult: WorkspaceHLAResult): void {
  segments.push(
    {
      Type: 'OBX',
      Fields: [
        {
          RawValue: obxIndex.toString(),
          Subs: null
        },
        {
          RawValue: 'NM',
          Subs: null
        },
        {
          RawValue: 'SA1_CPOS',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: Math.floor(dailyResult.neg).toString(),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO Trouver quelle date
          RawValue: ISOtoInlogDate(asDate(dailyResult.batchDate)),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TOD0 Chercher cette veleur
          RawValue: 'ssimonot',
          Subs: null
        }
      ]
    }
  )

  obxIndex++

  segments.push(
    {
      Type: 'OBX',
      Fields: [
        {
          RawValue: obxIndex.toString(),
          Subs: null
        },
        {
          RawValue: 'NM',
          Subs: null
        },
        {
          RawValue: 'SA1_CNEG',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: Math.floor(dailyResult.pos).toString(),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO Trouver quelle date
          RawValue: ISOtoInlogDate(asDate(dailyResult.batchDate)),
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TOD0 Chercher cette veleur
          RawValue: 'ssimonot',
          Subs: null
        }
      ]
    }
  )

  obxIndex++
}

function fillLotInfo(segments: HPRIMSegment[], dailyResult: WorkspaceHLAResult): void {
  segments.push(
    {
      Type: 'OBX',
      Fields: [
        {
          RawValue: obxIndex.toString(),
          Subs: null
        },
        {
          RawValue: 'TX',
          Subs: null
        },
        {
          RawValue: 'SA1_LOT',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: dailyResult.catalogId,
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO Trouver quelle date
          RawValue: date,
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TOD0 Chercher cette veleur
          RawValue: 'ssimonot',
          Subs: null
        }
      ]
    }
  )

  obxIndex++
}

function fillSynthesis(segments: HPRIMSegment[], synthesis: any, dailyResult: WorkspaceHLAResult): void {
  Object.keys(synthesis).forEach((key) => {
    for (let i = 0; i < synthesis[key].length; i++) {
      segments.push(
        {
          Type: 'OBX',
          Fields: [
            {
              RawValue: obxIndex.toString(),
              Subs: null
            },
            {
              RawValue: 'TX',
              Subs: null
            },
            {
              RawValue: key,
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: synthesis[key][i],
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: 'P',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              // TODO Trouver quelle date
              RawValue: ISOtoInlogDate(asDate(dailyResult.batchDate)),
              Subs: null
            },
            {
              RawValue: '',
              Subs: null
            },
            {
              // TOD0 Chercher cette veleur
              RawValue: 'ssimonot',
              Subs: null
            }
          ]
        }
      )

      obxIndex++
    }
  })
}

function fillProtocolInfo(segments: HPRIMSegment[]): void {
  segments.push(
    {
      Type: 'OBX',
      Fields: [
        {
          RawValue: obxIndex.toString(),
          Subs: null
        },
        {
          RawValue: 'TX',
          Subs: null
        },
        {
          RawValue: 'PROTIN',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'Texte du protocole choisi',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: 'P',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TODO Trouver quelle date
          RawValue: date,
          Subs: null
        },
        {
          RawValue: '',
          Subs: null
        },
        {
          // TOD0 Chercher cette veleur
          RawValue: 'ssimonot',
          Subs: null
        }
      ]
    }
  )

  obxIndex++
}

function fillSynthesisFooter(segments: HPRIMSegment[]): void {
  segments.push(
    {
      Type: 'C',
      Fields: [
        {
          RawValue: '1',
          Subs: null
        },
        {
          RawValue: 'L',
          Subs: null
        },
        {
          RawValue: '00',
          Subs: null
        }
      ]
    }
  )

  segments.push(
    {
      Type: 'L',
      Fields: [
        {
          RawValue: '1',
          Subs: null
        },
        {
          RawValue: '1',
          Subs: null
        },
        {
          RawValue: '184',
          Subs: null
        }
      ]
    }
  )
}

async function convertJsonToHprim(idToken: string, json: string): Promise<any> {
  return await fetchWithCredentials(
    idToken,
    `${Config.converterServerURL}/api/v1/converters/hprim`,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: json
    }
  )
    .then(async function (response) {
      return await response.blob()
    }).then(function (blob) {
      saveAs(blob, 'FU_EXPORT_' + date + '.HPR')
    })
};
