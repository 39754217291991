import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { selectIdToken } from '../selectors/session'
import { Action } from 'redux'
import { handleUnauthorizedResponseError } from './helper'
import {
  GetRoleAction,
  GET_ROLE_REQUEST,
  TENANTS_DATA_REQUEST,
  TENANTS_DATA_REQUEST_FAILURE,
  TENANTS_DATA_REQUEST_SUCCESS,
  TENANT_DATA_REQUEST,
  TENANT_DATA_REQUEST_FAILURE,
  TENANT_DATA_REQUEST_SUCCESS,
  TenantAction,
  GET_ROLE_REQUEST_FAILURE,
  GET_ROLE_REQUEST_SUCCESS,
  ADD_ROLE_REQUEST,
  AddRoleAction,
  ADD_ROLE_REQUEST_FAILURE,
  ADD_ROLE_REQUEST_SUCCESS,
  REMOVE_ROLE_REQUEST,
  RemoveRoleAction,
  REMOVE_ROLE_REQUEST_FAILURE,
  REMOVE_ROLE_REQUEST_SUCCESS,
  SWITCH_TENANT_REQUEST,
  SwitchTenantAction,
  SWITCH_TENANT_REQUEST_FAILURE,
  SWITCH_TENANT_REQUEST_SUCCESS
} from '../actions/tenant'
import { loadRoleRequest, loadTenantsDataRequest, addRoleRequest, deleteRoleRequest, switchTenantRequest } from '../util/tenant'

export function * tenantsSaga (): Generator {
  yield all([
    takeEvery(TENANTS_DATA_REQUEST, loadAllTenantsDataSaga),
    takeEvery(TENANT_DATA_REQUEST, loadTenantDataSaga),
    takeEvery(GET_ROLE_REQUEST, loadRoleSaga),
    takeEvery(ADD_ROLE_REQUEST, addRoleSaga),
    takeEvery(REMOVE_ROLE_REQUEST, removeRoleSaga),
    takeEvery(SWITCH_TENANT_REQUEST, switchTenantUserSaga),
    takeEvery(SWITCH_TENANT_REQUEST_SUCCESS, reloadAppSaga),
  ])
}

export function * loadAllTenantsDataSaga (action: Action): any {
  const accessToken = yield select(selectIdToken)
  let result
  try {
    result = yield call(loadTenantsDataRequest, accessToken)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: TENANTS_DATA_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: TENANTS_DATA_REQUEST_SUCCESS, result })
}

export function * loadTenantDataSaga (action: TenantAction): any {
  const tenant = action.tenant
  const accessToken = yield select(selectIdToken)
  let result
  try {
    result = yield call(loadTenantsDataRequest, accessToken, tenant)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: TENANT_DATA_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: TENANT_DATA_REQUEST_SUCCESS, result })
}

export function * loadRoleSaga (action: GetRoleAction): any {
  const tenant = action.tenant
  const subject = action.subject
  const accessToken = yield select(selectIdToken)
  let result
  try {
    result = yield call(loadRoleRequest, accessToken, tenant, subject)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: GET_ROLE_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: GET_ROLE_REQUEST_SUCCESS, result, subject })
}

export function * removeRoleSaga (action: RemoveRoleAction): any {
  const accessToken = yield select(selectIdToken)
  const subject = action.subject
  const role = action.role
  const tenant = action.tenant
  let result
  try {
    result = yield call(deleteRoleRequest, accessToken, tenant, subject, role)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: REMOVE_ROLE_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: REMOVE_ROLE_REQUEST_SUCCESS, result, tenant, subject, role })
}

export function * addRoleSaga (action: AddRoleAction): any {
  const accessToken = yield select(selectIdToken)
  const subject = action.subject
  const role = action.role
  const tenant = action.tenant
  let result
  try {
    result = yield call(addRoleRequest, accessToken, tenant, subject, role)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: ADD_ROLE_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: ADD_ROLE_REQUEST_SUCCESS, result, tenant, subject, role })
}

export function * switchTenantUserSaga (action: SwitchTenantAction): any {
  const accessToken = yield select(selectIdToken)
  const tenant = action.tenant
  let result
  try {
    result = yield call(switchTenantRequest, accessToken, tenant)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: SWITCH_TENANT_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: SWITCH_TENANT_REQUEST_SUCCESS, result, tenant })
}

export function reloadAppSaga() {
  window.location.reload();
}
