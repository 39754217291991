/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultCodeDataType = exports.AnalysisRequestCategories = exports.getAnalysisTypesFromCatalogId = exports.AnalysisRequestType = exports.AnalysisRequestState = void 0;
// Voir CCTP-IT09.pdf - II. STATUTS ET CYCLES DE VIE, A. STATUTS ET CYCLES DE VIE
var AnalysisRequestState;
(function (AnalysisRequestState) {
    // Stade 1 - La demande est prise en compte (reçu d’Inlog Labo server)
    AnalysisRequestState["TakenIntoAccount"] = "taken-into-account";
    // Stade 2 - Les examens de la demande sont intégrés dans des listes de travail qui sont exportés vers les automates
    AnalysisRequestState["IntegratedWorklist"] = "integrated-worklist";
    // Stade 3 - Des résultats sont importés dans le middleware pour chaque examen de cette
    // demande, à partir de HLA-FUSION et / ou Match-it. Ils sont soumis aux algorithmes
    // d'aide à l'interprétation paramétrés par labo
    AnalysisRequestState["ReceivedResults"] = "received-results";
    // Stade 4 - Les résultats de l'examens sont validés techniquement (VT), les résultats
    // peuvent être dévalidés par les profils utilisateurs définis
    AnalysisRequestState["TechValidatedResults"] = "tech-validated-results";
    // Stade 5 - Les résultats de l'examens sont validés avec composante experte (VE) par
    // les profils utilisateurs définis. Les résultats au stade VE ne peuvent être dévalidés et
    // modifiés que par un biologiste médical pour revenir à un stade VT.
    AnalysisRequestState["ExpertValidatedResults"] = "expert-validated-results";
    // Stade 6 - Les résultats sont transférés dans inlog laboserveur en format norme HPRIM
    // les résultats peuvent être dévalidés par un biologiste médical pour repasser au stade
    // pour être modifiable, puis passage en VE, puis réexportable après validation.
    AnalysisRequestState["TransferedResults"] = "transfered-results";
    // Stade 0 - Reprise des demandes données antérieures à la première nouvelle demande
    // validée dans l'outil – non exportable – non dévalidable
    //
    // Précision apportée par @srubier:
    // "C'est pour permettre d'interroger un dossier patient quand celui-ci n'a jamais reçu 
    // une demande analytique. Je précise. On prévoit envoyer un hprim pour un patient avec 
    // un code spécifique qui permet juste de déclencher le pipeline hprim import sans que 
    // ce soit un examen. Une sorte de première intégration pour consultation."
    AnalysisRequestState["Historized"] = "historized";
})(AnalysisRequestState || (exports.AnalysisRequestState = AnalysisRequestState = {}));
// Types d'analyses pris en charge
// Voir https://nextcloud.cadoles.com/nextcloud/index.php/apps/files/?dir=/EFS-Shela/DOC09-SpecificationsFonctionnelsDetaillees&fileid=190281
var AnalysisRequestType;
(function (AnalysisRequestType) {
    // DEPH
    AnalysisRequestType["DEPHLA"] = "DEPHLA";
    AnalysisRequestType["DEPHLAA"] = "DEPHLAA";
    AnalysisRequestType["DEPHLAT"] = "DEPHLAT";
    //PRACL
    AnalysisRequestType["PRACL1"] = "PRACL1";
    AnalysisRequestType["PRACL1A"] = "PRACL1A";
    AnalysisRequestType["PRACL2"] = "PRACL2";
    AnalysisRequestType["PRACL2A"] = "PRACL2A";
    // SACL
    AnalysisRequestType["SACL1"] = "SACL1";
    AnalysisRequestType["SACL1A"] = "SACL1A";
    AnalysisRequestType["SACL2"] = "SACL2";
    AnalysisRequestType["SACL2A"] = "SACL2A";
    AnalysisRequestType["SACL1T"] = "SACL1T";
    AnalysisRequestType["SACL2T"] = "SACL2T";
    AnalysisRequestType["SACL1D"] = "SACL1D";
    AnalysisRequestType["SACL2D"] = "SACL2D";
    // DL
    AnalysisRequestType["DL1"] = "DL1";
    AnalysisRequestType["DL2"] = "DL2";
    AnalysisRequestType["DL1ADS"] = "DL1ADS";
    AnalysisRequestType["DL2ADS"] = "DL2ADS";
    // HD
    AnalysisRequestType["HD1"] = "HD1";
    AnalysisRequestType["HD2"] = "HD2";
    AnalysisRequestType["HD3"] = "HD3";
    AnalysisRequestType["HD4"] = "HD4";
    AnalysisRequestType["HD5"] = "HD5";
    AnalysisRequestType["HD6"] = "HD6";
    AnalysisRequestType["HD7"] = "HD7";
    AnalysisRequestType["HD8"] = "HD8";
    AnalysisRequestType["HD9"] = "HD9";
    AnalysisRequestType["HD1S1"] = "HD1S1";
    AnalysisRequestType["HD1S2"] = "HD1S2";
    AnalysisRequestType["HD1S3"] = "HD1S3";
    AnalysisRequestType["HD1S4"] = "HD1S4";
    AnalysisRequestType["HD1S5"] = "HD1S5";
    AnalysisRequestType["HD1S6"] = "HD1S6";
    AnalysisRequestType["HD1S7"] = "HD1S7";
    AnalysisRequestType["HD1S8"] = "HD1S8";
    AnalysisRequestType["HD1S9"] = "HD1S9";
    AnalysisRequestType["HD2S1"] = "HD2S1";
    AnalysisRequestType["HD2S2"] = "HD2S2";
    AnalysisRequestType["HD2S3"] = "HD2S3";
    AnalysisRequestType["HD2S4"] = "HD2S4";
    AnalysisRequestType["HD2S5"] = "HD2S5";
    AnalysisRequestType["HD2S6"] = "HD2S6";
    AnalysisRequestType["HD2S7"] = "HD2S7";
    AnalysisRequestType["HD2S8"] = "HD2S8";
    AnalysisRequestType["HD2S9"] = "HD2S9";
    // DLU
    AnalysisRequestType["DLUO"] = "DLUO";
    AnalysisRequestType["DLUOA"] = "DLUOA";
    AnalysisRequestType["DLUI"] = "DLUI";
    // SA
    AnalysisRequestType["SA1"] = "SA1";
    AnalysisRequestType["SA2"] = "SA2";
    AnalysisRequestType["SA1-ADS"] = "SA1-ADS";
    AnalysisRequestType["SA2-ADS"] = "SA2-ADS";
    AnalysisRequestType["SA1-T"] = "SA1-T";
    AnalysisRequestType["SA2-T"] = "SA2-T";
    AnalysisRequestType["SA1-D"] = "SA1-D";
    AnalysisRequestType["SA2-D"] = "SA2-D";
    // RDSA
    AnalysisRequestType["RDSA1O"] = "RDSA1O";
    AnalysisRequestType["RDSA20"] = "RDSA20";
    AnalysisRequestType["RDSA1OA"] = "RDSA1OA";
    AnalysisRequestType["RDSA2OA"] = "RDSA2OA";
    AnalysisRequestType["RDSA1OT"] = "RDSA1OT";
    AnalysisRequestType["RDSA2OT"] = "RDSA2OT";
    AnalysisRequestType["RDSA1OD"] = "RDSA1OD";
    AnalysisRequestType["RDSA2OD"] = "RDSA2OD";
    AnalysisRequestType["RDSA1I"] = "RDSA1I";
    AnalysisRequestType["RDSA2I"] = "RDSA2I";
    AnalysisRequestType["RDSA1IA"] = "RDSA1IA";
    AnalysisRequestType["RDSA2IA"] = "RDSA2IA";
    AnalysisRequestType["RDSA1IT"] = "RDSA1IT";
    AnalysisRequestType["RDSA2IT"] = "RDSA2IT";
    AnalysisRequestType["RDSA1ID"] = "RDSA1ID";
    AnalysisRequestType["RDSA2ID"] = "RDSA2ID";
})(AnalysisRequestType || (exports.AnalysisRequestType = AnalysisRequestType = {}));
// Table associative de correspondance entre les types d'analyses et les préfixes d'identifiant
// de catalogues
// Voir https://nextcloud.cadoles.com/nextcloud/index.php/apps/files/?dir=/EFS-Shela/DOC09-SpecificationsFonctionnelsDetaillees&fileid=190281
var AnalysisRequestTypeCatalogPrefixTable = {
    "LSM12": [AnalysisRequestType.DLUO, AnalysisRequestType.DLUOA],
    "LS1A04": [AnalysisRequestType.SACL1, AnalysisRequestType.SACL1T, AnalysisRequestType.SACL1D],
    "LS2A01": [AnalysisRequestType.SACL2, AnalysisRequestType.SACL2A, AnalysisRequestType.SACL2T, AnalysisRequestType.SACL2D],
    "LSA104": [AnalysisRequestType.SACL1A],
};
// Retourne la liste des types d'analyse associés à un identifiant de catalogue, ou une tableau vide
// si aucun préfixe n'est associé à l'identifiant de catalogue
var getAnalysisTypesFromCatalogId = function (catalogId) {
    var prefixes = Object.keys(AnalysisRequestTypeCatalogPrefixTable);
    for (var i = 0; i < prefixes.length; i++) {
        var prefix = prefixes[i];
        if (!catalogId.startsWith(prefix))
            continue;
        return AnalysisRequestTypeCatalogPrefixTable[prefix];
    }
    return [];
};
exports.getAnalysisTypesFromCatalogId = getAnalysisTypesFromCatalogId;
exports.AnalysisRequestCategories = {
    DEP: {
        label: 'DEPHLA',
        types: new Set([AnalysisRequestType.DEPHLA, AnalysisRequestType.DEPHLAA, AnalysisRequestType.DEPHLAT])
    },
    PRA1: {
        label: 'PRA1',
        types: new Set([AnalysisRequestType.PRACL1, AnalysisRequestType.PRACL1A])
    },
    PRA2: {
        label: 'PRA2',
        types: new Set([AnalysisRequestType.PRACL2, AnalysisRequestType.PRACL2A])
    },
    SACL1: {
        label: 'SACL1 (HD)',
        types: new Set([AnalysisRequestType.SACL1, AnalysisRequestType.SACL1A])
    },
    SACL2: {
        label: 'SACL2 (HD)',
        types: new Set([AnalysisRequestType.SACL2, AnalysisRequestType.SACL2A])
    }
};
// Type de donnée associé au code de résultat
// d'examen
// Voir https://nextcloud.cadoles.com/nextcloud/index.php/f/223720
var ResultCodeDataType;
(function (ResultCodeDataType) {
    // Groupe de résultats
    ResultCodeDataType["RESULT_GROUP"] = "result-group";
    // MFI CP
    ResultCodeDataType["MFI_CP"] = "mfi-cp";
    // MFI CN
    ResultCodeDataType["MFI_CN"] = "mfi-cn";
    // MFI max (hors CP)
    ResultCodeDataType["MFI_MAX"] = "mfi-max";
    // Synthése des spécificité positives
    ResultCodeDataType["SYNTH_POS"] = "synth-pos";
    // Commentaire libre
    ResultCodeDataType["COM"] = "com";
    // Test complémentaire
    ResultCodeDataType["COMP_TEST"] = "comp-test";
    // Catalogue
    ResultCodeDataType["CAT"] = "cat";
})(ResultCodeDataType || (exports.ResultCodeDataType = ResultCodeDataType = {}));
