import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux'
import { RootState } from '../reducers/root'
import { ClearTransactionsAction, Transaction } from '../reducers/transactions';
import { uuid } from '../util/uuid'

export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS'

export function clearTransactions(txIds: string[]): ClearTransactionsAction {
  return { type: CLEAR_TRANSACTIONS, txIds }
}

export interface TransactionalAction extends Action {
  txId: string
}

export type WithTx = (name: string | number, action: Action | Action[]) => Array<TransactionalAction> | TransactionalAction

export type TxStatus = (name: string | number) => Transaction

const defaultTransaction: Transaction = {
  done: false,
  loading: false,
  started: false,
  errors: [],
  results: [],
}

interface TransactionAggregate extends Transaction {
  ids: string[]
}

export function useTransactions(): { withTx: WithTx, txStatus: TxStatus } {
  const dispatch = useDispatch()
  const ref = useRef({ txs: [] as string[] })

  const [aggregates, setAggregates] = useState<{ [name: string]: TransactionAggregate }>({})
  const [localTransactions, setLocalTransactions] = useState<{ [name: string]: string[] }>({})

  const withTx = useCallback((name: string | number, action: Action | Action[]) => {
    setAggregates(aggregates => {
      const newAggregates = { ...aggregates }
      delete newAggregates[name]
      return newAggregates
    })

    if (Array.isArray(action)) {
      const txIds: string[] = []
      const actionList = action.map(action => {
        const txId = uuid()
        ref.current?.txs.push(txId)
        txIds.push(txId)
        return { ...action, txId }
      })

      setLocalTransactions(localTransactions => ({ ...localTransactions, [name]: txIds }))

      return actionList
    } else {
      const txId = uuid()
      ref.current?.txs.push(txId)

      setLocalTransactions(localTransactions => ({ ...localTransactions, [name]: [txId] }))

      return { ...action, txId }
    }
  }, [])

  const transactions = useSelector((state: RootState) => state.transactions.byId)

  useEffect(() => {
    setAggregates(aggregates => {
      let changed = false
      const newAggregates = { ...aggregates };

      Object.keys(localTransactions).forEach(name => {
        const aggr = aggregates[name] || { ...defaultTransaction, ids: localTransactions[name] };

        const txs: Transaction[] = aggr.ids.reduce((memo, txId) => {
          if (!transactions[txId]) return memo;
          memo.push(transactions[txId]);
          return memo;
        }, [] as Transaction[])

        const newAggr: TransactionAggregate = {
          ...aggr,
          started: txs.some(tx => tx.started),
          done: txs.every(tx => tx.started && tx.done),
          loading: txs.some(tx => tx.loading),
          errors: txs.reduce((errors, tx) => [...errors, ...(Array.isArray(tx.errors) ? tx.errors : [])], []),
          results: txs.reduce((results, tx) => [...results, ...(Array.isArray(tx.results) ? tx.results : [])], []),
        }

        const aggrChanged = newAggr.done !== aggr.done ||
          newAggr.loading !== aggr.loading ||
          newAggr.started !== aggr.started ||
          newAggr.errors?.length !== aggr.errors?.length ||
          newAggr.results?.length !== aggr.results?.length

        if (aggrChanged) {
          changed = true
          newAggregates[name] = newAggr
        }
      })

      if (!changed) return aggregates;

      return newAggregates;
    })
  }, [localTransactions, transactions]);

  const txStatus = useCallback((name: string | number): Transaction => {
    return aggregates[name] ?? defaultTransaction
  }, [aggregates])

  useEffect(() => {
    return () => {
      dispatch(clearTransactions(ref.current?.txs))
    }
  }, [])


  return { withTx, txStatus }
}

export function forwardTx(origin: Partial<TransactionalAction>, action: Action): Action | TransactionalAction {
  if (!origin.txId) {
    return action
  }

  return {
    ...action,
    txId: origin.txId,
  }
}