import { all, call, put, race, take, takeEvery } from 'redux-saga/effects'
import { SEARCH_OBJECT_REQUEST, SEARCH_OBJECT_REQUEST_SUCCESS, SEARCH_OBJECT_REQUEST_FAILURE, SearchAction, SearchResult } from '../actions/search'
import { handleUnauthorizedResponseError } from './helper'
import { QUERY_OBJECTS_FAILURE, QUERY_OBJECTS_SUCCESS, QueryObjectsFailureAction, QueryObjectsSuccessAction, queryObjects } from '../actions/objects';
import { ObjectFilter, PrivacyDomain, StoreObject } from '../util/objects';

export function* searchSaga(): Generator<any, any, any> {
  yield all([
    takeEvery(SEARCH_OBJECT_REQUEST, searchObjects)
  ])
}
export function* searchObjects(action: SearchAction): any {
  const results: SearchResult[] = []
  const found = new Set<string>()

  const addResult = (obj: StoreObject, namespace: string, privacyDomain: PrivacyDomain) => {
    if (found.has(obj.id)) return;
    found.add(obj.id)
    results.push({ ...obj, namespace, privacyDomain })
  }

  try {
    for (let i = 0; i < action.queries.length; i++) {
      const query = action.queries[i];

      yield put(queryObjects(query.namespace, query.privacyDomain, query.filter, { hydrate: true }))

      let req: { success: QueryObjectsSuccessAction, failure: QueryObjectsFailureAction } = yield race({
        success: take(QUERY_OBJECTS_SUCCESS),
        failure: take(QUERY_OBJECTS_FAILURE)
      })

      if (req.failure) {
        throw req.failure.err
      }

      let objects = req.success.objects.filter(obj => "data" in obj) as StoreObject[]

      if (!query.asRef) {
        objects.forEach(obj => addResult(obj, query.namespace, query.privacyDomain))
        continue
      }

      if (objects.length === 0) continue

      const targetNamespace = query.asRef.targetNamespace
      const targetPrivacyDomain = query.asRef.targetPrivacyDomain

      const filters: ObjectFilter[] = []

      objects.forEach(obj => {
        const op = query.asRef?.targetFilter(obj)
        if (!op) return;
        filters.push(op)
      })

      yield put(queryObjects(targetNamespace, targetPrivacyDomain, { or: filters }, { hydrate: true }))

      req = yield race({
        success: take(QUERY_OBJECTS_SUCCESS),
        failure: take(QUERY_OBJECTS_FAILURE)
      })

      if (req.failure) {
        throw req.failure.err
      }

      objects = req.success.objects.filter(obj => "data" in obj) as StoreObject[]
      objects.forEach(obj => addResult(obj, targetNamespace, targetPrivacyDomain))
    }
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: SEARCH_OBJECT_REQUEST_FAILURE, err })
    return
  }



  yield put({ type: SEARCH_OBJECT_REQUEST_SUCCESS, results })
}
