import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { Config } from '../config'
import { selectSession } from '../selectors/session'
import { handleUnauthorizedResponseError } from './helper'

import { AUDIT_REQUEST, AUDIT_REQUEST_FAILURE, AUDIT_REQUEST_SUCCESS, GetAuditLogAction } from '../actions/audit'
import { AuditServerClient } from '../util/audit'

export function* auditSaga(): Generator {
  yield all([
    takeEvery(AUDIT_REQUEST, loadAuditLogs)
  ])
}

export function* loadAuditLogs(action: GetAuditLogAction): any {
  const session = yield select(selectSession)
  let result
  const client = new AuditServerClient(Config.authzServerURL, session.idToken)
  try {
    result = yield call(client.queryLogs, action.options, action.filter)
  } catch (err) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, err, action)
    if (handled) return

    yield put({ type: AUDIT_REQUEST_FAILURE, err })
    return
  }

  yield put({ type: AUDIT_REQUEST_SUCCESS, result })
}
