import { all, call, CallEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { Config } from '../config'
import { NoHashQueryStringUtils } from '../util/noHashQueryStringUtils'
import {
  USER_LOGIN_CHECK_FAILURE,
  USER_LOGIN_CHECK_SUCCESS,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_FAILURE,
  USER_LOGOUT_REQUEST_SUCCESS,
  USER_LOGOUT_REQUEST_FAILURE,
  USER_LOGIN_REQUEST_REQUEST,
  USER_LOGOUT_REQUEST_REQUEST,
  USER_LOGIN_CHECK_REQUEST,
  REFRESH_ACCESS_TOKEN_REQUEST,
  REFRESH_ACCESS_TOKEN_FAILURE,
  REFRESH_ACCESS_TOKEN_SUCCESS,
  USER_LOGIN_INFO_REQUEST_FAILURE,
  USER_LOGIN_INFO_REQUEST_SUCCESS
} from '../actions/session'
import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  AuthorizationNotifier,
  FetchRequestor, LocalStorageBackend,
  DefaultCrypto,
  TokenRequest,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  StringMap,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenResponse
} from '@openid/appauth'
import {
  selectAccessToken,
  selectIdToken,
  selectIsLoggedIn,
  selectRefreshToken
} from '../selectors/session'
import { Action } from 'redux'
import { fetchWithCredentials } from '../util/fetch'

export function * sessionSaga (): Generator {
  yield all([
    takeEvery(USER_LOGIN_REQUEST_REQUEST, userLoginRequestSaga),
    takeEvery(USER_LOGOUT_REQUEST_REQUEST, userLogoutRequestSaga),
    takeEvery(USER_LOGIN_CHECK_REQUEST, userLoginCheckSaga),
    takeLatest(REFRESH_ACCESS_TOKEN_REQUEST, refreshAccessTokenSaga),
    takeLatest(USER_LOGIN_CHECK_SUCCESS, userLoginInfo),
    takeLatest(USER_LOGIN_REQUEST_SUCCESS, userLoginInfo)
  ])
}

export function * userLoginRequestSaga (action: any): any {
  let result
  try {
    const isLoggedIn: boolean = yield select(selectIsLoggedIn)
    result = yield call(userLoginRequest, isLoggedIn)
  } catch (err) {
    yield put({ type: USER_LOGIN_REQUEST_FAILURE, err })
    return
  }
  yield put({ type: USER_LOGIN_REQUEST_SUCCESS, result })
}

export function * userLogoutRequestSaga (action: any): any {
  try {
    let idToken: string = yield select(selectIdToken)
    if (idToken === '') {
      idToken = sessionStorage.getItem('idToken') ?? ''
    }

    const logoutEndpoint = `${Config.authzServerURL}/logout`
    yield call(fetchWithCredentials, idToken, logoutEndpoint)

    let accessToken: string = yield select(selectAccessToken)
    if (accessToken === '') {
      accessToken = sessionStorage.getItem('accessToken') ?? ''
    }

    sessionStorage.removeItem('idToken')
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')

    window.location.href = `${Config.oidcLogoutURL}?id_token_hint=${idToken}&post_logout_redirect_uri=${Config.oidcPostLogoutRedirectURL}`
  } catch (err) {
    yield put({ type: USER_LOGOUT_REQUEST_FAILURE, err })
    return
  }
  yield put({ type: USER_LOGOUT_REQUEST_SUCCESS })
}

export function * userLoginCheckSaga (action: any): any {
  let result
  try {
    result = yield call(userLoginCheck)
  } catch (err) {
    yield put({ type: USER_LOGIN_CHECK_FAILURE, err })
    return
  }
  yield put({ type: USER_LOGIN_CHECK_SUCCESS, result })
}

function * refreshAccessTokenSaga (action: Action): Generator<any, any, any> {
  const refreshToken = yield select(selectRefreshToken)
  let tokenResponse: TokenResponse
  try {
    tokenResponse = yield call(refreshAccessToken, refreshToken as string)
  } catch (error) {
    yield put({ type: REFRESH_ACCESS_TOKEN_FAILURE, error })
    return
  }

  yield put({
    type: REFRESH_ACCESS_TOKEN_SUCCESS,
    refreshToken: tokenResponse.refreshToken,
    accessToken: tokenResponse.accessToken
  })
}
export function * userLoginInfo (action: any): any {
  let result
  try {
    const accessToken = yield select(selectIdToken)
    result = yield call(getUserSessionInfo, accessToken)
  } catch (error) {
    yield put({ type: USER_LOGIN_INFO_REQUEST_FAILURE, error })
    return
  }
  yield put({ type: USER_LOGIN_INFO_REQUEST_SUCCESS, result })
}
async function userLoginRequest (isLoggedIn: boolean): Promise<void> {
  const promise = new Promise<void>((resolve, reject) => {
    if (isLoggedIn) {
      resolve()
    }

    const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto())

    const idTokenHint = sessionStorage.getItem('idToken')

    const extras: StringMap = {}
    if (idTokenHint !== null) {
      extras.id_token_hint = idTokenHint
    }

    console.log(Config)

    AuthorizationServiceConfiguration.fetchFromIssuer(Config.oidcBaseURL, new FetchRequestor())
      .then((response) => {
        const authRequest = new AuthorizationRequest({
          client_id: Config.oidcClientID,
          redirect_uri: Config.oidcPostLoginRedirectURL,
          scope: 'openid profile email roles offline_access',
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          extras,
          state: undefined
        }, new DefaultCrypto(), true)

        authorizationHandler.performAuthorizationRequest(response, authRequest)
        resolve()
      })
      .catch(reject)
  })

  return await promise
}

async function userLoginCheck (): Promise<unknown> {
  const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
  const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto())

  const promise = new Promise((resolve, reject) => {
    const notifier = new AuthorizationNotifier()
    authorizationHandler.setAuthorizationNotifier(notifier)

    notifier.setAuthorizationListener((request, response, error) => {
      if (response !== null) {
        let extras = {}

        if (request?.internal !== null && request?.internal !== undefined) {
          extras = {
            code_verifier: request.internal.code_verifier
          }
        }

        const tokenRequest = new TokenRequest({
          client_id: Config.oidcClientID,
          redirect_uri: Config.oidcPostLoginRedirectURL,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: response.code,
          refresh_token: undefined,
          extras: extras
        })

        AuthorizationServiceConfiguration.fetchFromIssuer(Config.oidcBaseURL, new FetchRequestor())
          .then((oResponse) => {
            const configuration = oResponse
            return tokenHandler.performTokenRequest(configuration, tokenRequest)
              .then((oResponse) => {
                if (oResponse === null) {
                  throw new Error("No response from issuer !")
                }
    
                if (!oResponse.idToken) {
                  throw new Error("Could not find idToken in issuer response !")
                }

                const info =  parseJwt(oResponse.idToken)
    
                const result = {
                  isLoggedIn: true,
                  accessToken: oResponse.accessToken,
                  refreshToken: oResponse.refreshToken,
                  idToken: oResponse.idToken,
                  user: {
                    subject: info.email,
                    roles: info.roles ?? []
                  }
                }
    
                sessionStorage.setItem('idToken', oResponse.idToken !== undefined ? oResponse.idToken : '')
                sessionStorage.setItem('accessToken', oResponse.accessToken !== undefined ? oResponse.accessToken : '')
                sessionStorage.setItem('refreshToken', oResponse.refreshToken !== undefined ? oResponse.refreshToken : '')
    
                return result
              })
          })
          .then(resolve)
          .catch(reject)
      }
    })

    authorizationHandler.completeAuthorizationRequestIfPossible()
      .catch(reject)
  })

  return await promise
}

const refreshAccessToken = async (refreshToken: string): Promise<TokenResponse> => {
  return await AuthorizationServiceConfiguration.fetchFromIssuer(Config.oidcBaseURL, new FetchRequestor())
    .then(async (config) => {
      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
      const tokenRequest = new TokenRequest({
        client_id: Config.oidcClientID,
        grant_type: GRANT_TYPE_REFRESH_TOKEN,
        refresh_token: refreshToken,
        redirect_uri: Config.oidcPostLoginRedirectURL
      })

      return await tokenHandler.performTokenRequest(config, tokenRequest)
    })
}

const getUserSessionInfo = (idToken: string): any => {
  const url = `${Config.authzServerURL}/api/v1/session`

  return fetchWithCredentials(idToken, url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(async response => await response.json())
    .then(function (response) {
      return response
    })
}

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
