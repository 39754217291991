import { Action } from "redux"
import { ObjectFilter, QueryObjectsOptions } from "../util/objects"
import { EventObject } from "../util/events"

export const EVENT_REQUEST = 'EVENT_REQUEST'
export const EVENT_REQUEST_SUCCESS = 'EVENT_REQUEST_SUCCESS'
export const EVENT_REQUEST_FAILURE = 'EVENT_REQUEST_FAILURE'

export interface fetchEventsAction extends Action {
  options?: QueryObjectsOptions
  filter?: ObjectFilter
}
export interface FetchEventsSuccessAction extends Action {
  events: EventObject[]
}

export function fetchEvents(options?: QueryObjectsOptions, filter?: ObjectFilter): fetchEventsAction {
  return {
    type: EVENT_REQUEST,
    options,
    filter
  }
};
