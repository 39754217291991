import { ExtendableError } from './error'

export async function fetchWithCredentials (idToken: string, url: string, init: RequestInit = {}): Promise<Response> {
  const params: RequestInit = {
    ...init,
    credentials: 'include',
    headers: {
      ...init.headers,
      Authorization: `Bearer ${idToken}`
    }
  }

  return await fetch(url, params)
    .then(handleUnauthorizedResponse)
}

export class UnauthorizedResponseError extends ExtendableError {
  constructor () {
    super('Unauthorized HTTP request.')
    Object.setPrototypeOf(this, UnauthorizedResponseError.prototype)
  }
}

export class ForbiddenResponseError extends ExtendableError {
  constructor () {
    super('Forbidden HTTP request.')
    Object.setPrototypeOf(this, ForbiddenResponseError.prototype)
  }
}

export function handleUnauthorizedResponse (res: Response): Response {
  if (res.status === 401) {
    throw new UnauthorizedResponseError()
  }

  if (res.status === 403) {
    throw new ForbiddenResponseError()
  }

  return res
}
